// Pickup
export const PickupStep = {
  FAR_AWAY: 'far_away',
  AREA_SELECTION: 'area_selection',
  PREVIOUS_STEP: 'prev_step',
  AREA: 'area',
} as const
export type PickupStepType = (typeof PickupStep)[keyof typeof PickupStep]

// RateOrder
export const RateOrderStep = {
  STARS: 'stars',
  RATING: 'rating',
  FINAL_STEP: 'final_step',
} as const

export type RateOrderStepType = (typeof RateOrderStep)[keyof typeof RateOrderStep]
